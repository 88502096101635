import React from 'react'
import image from './cat-cropped-small.png';

function ClickableImg(props) {

    const klapp = props.klapp;

    const [squash, setSquash] = React.useState(0)
    return (
        <img
            className="clickableCat"
            src={image}
            alt="cat"
            onMouseDown={() => {
                setSquash(1);
                if(squash == 0){
                    klapp();
                }
            }}
            onAnimationEnd={() => setSquash(0)}
            squash={squash}
        />
    )
}

export default ClickableImg
