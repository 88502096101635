import React from 'react'

function TotalKlapps(props)
{
    const total = props.total;

    return(
        <div className='totalKlappsContainer'>
            <h2 className='totalKlappsText'>Total klapps:</h2>
            <h2 className='totalKlappsText totalKlappsNumber'>{total}</h2>

        </div>
    )
}

export default TotalKlapps;