// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc, serverTimestamp, getCountFromServer } from "firebase/firestore";

import ClickableImg from './clickable-image';
import TotalKlapps from "./TotalKlapps";

import './App.css';
import { useEffect, useState } from "react";


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCEe5Pm6pY1RIp1_DlDcIdvaDByVBBOOSg",
  authDomain: "catklappr.firebaseapp.com",
  projectId: "catklappr",
  storageBucket: "catklappr.appspot.com",
  messagingSenderId: "307530198673",
  appId: "1:307530198673:web:e3918ffa4c41ae1e099930",
  measurementId: "G-M2LBHVY9DE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const firestore = getFirestore();

function App() {

  const [currentKlapps, setCurrentKlapps] = useState(0)

  const [totalKlapps, setTotalKlapps] = useState(0);

  async function updateTotalKlapps() {
    const klappsCollection = collection(firestore, 'klapps');

    const snapshot = await getCountFromServer(klappsCollection);

    console.log("total klapps: ", snapshot.data().count);

    setTotalKlapps(snapshot.data().count);
  }

  const handleClick = async () => {
    setTotalKlapps(totalKlapps + 1)
    
    try 
    {
      console.log("Trying to klapp...");
  
      setCurrentKlapps(currentKlapps + 1);
  
      const newDoc = await addDoc(collection(firestore, "klapps"), {
        klapps: 1,
        time: serverTimestamp(),
      });
  
      console.log("Klapped wiht ID: ", newDoc.id);
    } 
    catch (e) 
    {
      console.error("Something whent wrong when trying to klapp: ", e);
    }

    updateTotalKlapps();

  }

  //Run once.
  useEffect(() => {
    updateTotalKlapps();
  }, []);

  return (
    <div className="App">
      
      <h1 className="title">Catklappr</h1>
        <p className="splashText">(click the cat to klappa)</p>

      <div className='catContainer'>
      
        <ClickableImg klapp={handleClick} />

      </div>

      <div className="statsContainer">
      
        <p className="sessionKlappsText">klapps this session: {currentKlapps}</p>
      

        <TotalKlapps total={totalKlapps}/>
      
      </div>
        

    </div>
  );
}

export default App;
